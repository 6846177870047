<template>
    <section class="py-7 of-h">
        <img src="../../assets/img/layers/layer-02.png" alt="#" class="layout-bg">
        <div class="second-bg"></div>
        <div class="container text-center">
            <h2 class="font-weight-semibold mb-5 text-center z-index-4">
                Our Focus
            </h2>
            <div class="row" v-if="workList">
                <div class="col-lg-6 fl-x-cc">
                    <div class="text-white">
                        <router-link :to="'/programs/' + i.id + '/'" v-for="(i, k) in workList"
                                     class="fl-x fl-a-c works-img-cont text-decoration-none cursor-ponter"
                                     v-bind:key="k">
                            <div class="bg-white img-contain mr-3 fl-x fl-a-c fl-j-c p-3">
                                <div class="p-3">
                                    <img v-if="i.name === 'Skill Training & Livelihood'"
                                         src="../../assets/img/icons/teaching.png" alt="" class="img-fluid">
                                    <img v-if="i.name === 'Education'" src="../../assets/img/icons/education.png" alt=""
                                         class="img-fluid">
                                    <img v-if="i.name === 'Healthcare'"
                                         src="../../assets/img/icons/health-insurance.png" alt="" class="img-fluid">
                                    <!--                                    <img v-else src="../../assets/img/icons/teaching.png" alt="" class="img-fluid">-->
                                </div>
                            </div>
                            <h2 class="bottom-bar text-left header-res-text">{{ i.name }}
                            </h2>
                        </router-link>
                        <!--                        <div class="fl-x fl-a-c works-img-cont cursor-ponter" @click="switchTab('tab2')">-->
                        <!--                            <div class="bg-white img-contain mr-3 fl-x fl-a-c fl-j-c p-3">-->
                        <!--                                <div class="p-3">-->
                        <!--                                    <img src="../../assets/img/icons/education.png" alt="" class="img-fluid">-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <h2 class="bottom-bar header-res-text">Education</h2>-->
                        <!--                        </div>-->
                        <!--                        <div class="fl-x fl-a-c works-img-cont cursor-ponter" @click="switchTab('tab3')">-->
                        <!--                            <div class="bg-white img-contain mr-3 fl-x fl-a-c fl-j-c p-3">-->
                        <!--                                <div class="p-3">-->
                        <!--                                    <img src="../../assets/img/icons/health-insurance.png" alt="" class="img-fluid">-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <h2 class="bottom-bar header-res-text">Healthcare</h2>-->
                        <!--                        </div>-->
                    </div>
                </div>

                <div class="col-12 col-lg-6 fl-x fl-a-c">
                    <!--                    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut"-->
                    <!--                                mode="out-in">-->
                    <transition name="fade" mode="out-in">
<!--                        <iframe class="w-100 h-90 b-3" src="https://www.youtube.com/embed/7ZO6fkFbMJo"-->
<!--                                title="YouTube video player" frameborder="0"-->
<!--                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--                                allowfullscreen></iframe>-->
                        <iframe  key="'tab1" class="w-100 h-90 b-3"
                                src="https://www.youtube.com/embed/7ZO6fkFbMJo"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>

                        <!--                        <iframe v-if="activeTab === 'tab1'" key="'tab1" class="w-100 h-90 b-3"-->
                        <!--                                src="https://www.youtube.com/embed/UqjPwdIsm5s"-->
                        <!--                                title="YouTube video player" frameborder="0"-->
                        <!--                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
                        <!--                                allowfullscreen></iframe>-->
                        <!--                        <iframe v-if="activeTab === 'tab2'" key="'tab2" class="w-100 h-90 b-3"-->
                        <!--                                :src="link"-->
                        <!--                                title="YouTube video player" frameborder="0"-->
                        <!--                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
                        <!--                                allowfullscreen></iframe>-->
                        <!--                        <iframe key="'tab3" class="w-100 h-90 b-3" :src="link"-->
                        <!--                                title="YouTube video player" frameborder="0"-->
                        <!--                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
                        <!--                                allowfullscreen v-else></iframe>-->
                    </transition>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'OurWorks',
    data () {
        return {
            activeTab: 'tab1',
            link: '',
            loading: false,
            error: false,
            workList: null
        };
    },

    mounted () {
        this.loadData();
    },

    methods: {
        switchTab (item, link) {
            this.activeTab = item;
            this.link = link;
        },

        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            axios.get(urls.admin.program.list).then(function (response) {
                const json = response.data;
                that.workList = [...json.data];
                that.loading = false;
                that.setTab(that.workList);
            }).catch(function (exception) {
                that.loading = false;
                that.error = true;
                console.log('exception : => ', exception);
            });
        },

        setTab (array) {
            this.activeTab = 'tab1';
            this.link = array[0].link;
        }
    }
};
</script>

<style scoped lang="scss">
section {
    background-image: url('../../assets/img/bg/bg-01.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (min-width: 1200px) {
        min-height: 100vh;
    }

}

.layout-bg {
    position: absolute;
    right: 5%;
    bottom: 0;
    height: 37%;
    z-index: 0;
    @media (min-width: 768px) {
        height: 50%;
    }
    @media (min-width: 992px) {
        top: 0;
        height: 100%;
    }
}

.img-contain {
    z-index: 2;
    width: 8.75rem;
    height: auto;
}

.works-img-cont {
    margin-bottom: 15px;

    img {
        z-index: 1;
        @media (min-width: 992px) {
        }
    }

}

.works-img-cont {
    margin-bottom: 15px;

    img {
        z-index: 1;
    }

}

.donate-list {
    list-style: none;
    transition: 0.3s;

    li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 30px;

        &:before {
            content: "";
            position: absolute;
            left: 5px;
            top: 3px;
            width: 15px;
            height: 15px;
            background: url('../../assets/img/layers/layer-09.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

//iframe {
//    height: 16rem;
//}
iframe {
    z-index: 2;
}

@media (max-width: 992px) {
    iframe {
        //height: 20rem;
        height: 16rem;
    }
}

</style>
