<template>
    <div class="home">
        <section>
<!--            <Banner/>-->
            <banner-new></banner-new>
        </section>
        <section id="ourWorks">
            <OurWorks/>
        </section>
        <section id="impactStories">
            <ImpactComponent/>
        </section>
        <section id="presentWork">
            <PresentWork/>
        </section>
    </div>
</template>

<script>

// import Banner from '../views/components/Banner';
import ImpactComponent from '../views/components/ImpactComponent';
import OurWorks from '@/views/components/OurWorks';
import PresentWork from '@/views/components/PresentWork';
import BannerNew from '@/views/components/BannerNew';

export default {
    name: 'Home',
    components: {
        BannerNew,
        OurWorks,
        ImpactComponent,
        // Banner,
        PresentWork
    }
};

</script>
<style scoped>

</style>
