<template>
    <div>
        <nav class="menu">
            <input type="checkbox" checked href="#" class="menu-open" name="menu-open" id="menu-open"/>
            <label class="menu-open-button" for="menu-open">
                <!--                Happy & Healthy children, women/men, families & communities-->
                <div>
                    <span class="d-block font-weight-bold mb-2">Our Vision</span>
                    <p> Sustainable holistic transformation of the poor and the
                        marginalized resulting in equitable, dignified society globally.</p>
                </div>

            </label>

            <a v-for="(i, k) in impactItems" href="#" class="menu-item" v-bind:key="k" @click="openModal(i)">
                {{ i.name }}
            </a>
            <!--            <a href="#" class="menu-item">-->
            <!--              Education for children-->
            <!--            </a>-->
            <!--    <a href="#" class="menu-item">-->
            <!--      Young leadership-->
            <!--    </a>-->
            <!--    <a href="#" class="menu-item">-->
            <!--      Income & Nutrition-->
            <!--    </a>-->
            <!--    <a href="#" class="menu-item">-->
            <!--      Social and Economic Growth-->
            <!--    </a>-->
            <!--    <a href="#" class="menu-item">-->
            <!--      Health & Wellness-->
            <!--    </a>-->
            <!--    <a href="#" class="menu-item">-->
            <!--      Employability Entrepreneurship-->
            <!--    </a>-->
        </nav>

        <modal :title="title" ref="impactStoriesModal" class="colour-white modal-header-white"
               :no-close-on-backdrop="true"
               width="60r" header-color="primary" style="color: white!important;">
            <div class="row">
                <div class="col-6" v-for="(i, k) in links" v-bind:key="k">
                    <iframe key="'tab1" class="p-lg-3 h-lg-auto w-100"
                            :src="i.link"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'ImpactItem',

    data () {
        return {
            impactItems: null,
            loading: false,
            error: false,
            links: [],
            title: ''
        };
    },

    mounted () {
        this.loadData();
    },

    methods: {
        loadData () {
            const that = this;
            that.loading = true;
            axios.get(urls.admin.impactStory.list).then(function (response) {
                const json = response.data;
                that.impactItems = [...json.data];
                that.loading = false;
            }).catch(function (exception) {
                console.log('exception : => ', exception);
                that.loading = false;
                that.error = true;
            });
        },

        openModal (item) {
            this.title = item.name;
            this.links = item.impact_links;
            if (this.links.length > 0) {
                this.$refs.impactStoriesModal.show();
            } else {
                // alert('No Data...');
            }
        }
    }

};
</script>

<style scoped lang="scss">
.menu-item {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
    padding: 15px;
}

.menu-item,
.menu-open-button {
    font-size: 0.5rem;
    color: #3d3d3d;
    line-height: 12px;
    display: flex;
    height: 6.5rem;
    width: 6.5rem;
    align-items: center;
    justify-content: center;
    background: #EEEEEE;
    border-radius: 100%;
    /*margin-left: -40px;*/
    position: absolute;
    text-align: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform ease-out 200ms;
    transition: -webkit-transform ease-out 200ms;
    transition: transform ease-out 200ms;
    transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu-open-button {
    /*font-size: 13px;*/
    /*font-weight: 700;*/
    /*color: #3d3d3d;*/
    /*line-height: 24px;*/
    /*height: 11rem;*/
    /*width: 11rem;*/
    /*padding: 20px;*/
    /*border: 5px solid var(--color-primary);*/
    /*position: relative;*/
    font-size: 0.5rem;
    //font-weight: 600;
    color: #3d3d3d;
    line-height: 16px;
    height: 11rem;
    width: 11rem;
    padding: 23px;
    border: 5px solid var(--color-primary);
    position: relative;
}

.menu-open-button:before {
    content: "";
    height: 22rem;
    width: 22rem;
    padding: 20px;
    border: 8px solid var(--color-primary);
    border-radius: 50%;
    position: absolute;
}

.menu-open {
    display: none;
}

.lines {
    width: 25px;
    height: 3px;
    background: #596778;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12.5px;
    margin-top: -1.5px;
    -webkit-transition: -webkit-transform 200ms;
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
}

.line-1 {
    -webkit-transform: translate3d(0, -8px, 0);
    transform: translate3d(0, -8px, 0);
}

.line-2 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.line-3 {
    -webkit-transform: translate3d(0, 8px, 0);
    transform: translate3d(0, 8px, 0);
}

.menu-open:checked + .menu-open-button .line-1 {
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
    transform: translate3d(0, 0, 0) rotate(45deg);
}

.menu-open:checked + .menu-open-button .line-2 {
    -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
    transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.menu-open:checked + .menu-open-button .line-3 {
    -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
    transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu {
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 26px;
}

.menu-item {
    transition: all 0.01s ease 0s;
    text-decoration: none;
    z-index: 0;
}

.menu-item:hover {
    background: #d2d2d2;
    color: var(--color-primary);
    text-decoration: none;
    z-index: 2;
}

.menu-item:nth-child(3) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(4) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(5) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(6) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(7) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(8) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(9) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-open-button {
    z-index: 2;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
    -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
    transform: scale(1.1, 1.1) translate3d(0, 0, 0);
    cursor: pointer;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
}

.menu-open-button:hover {
    -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
    transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;
    -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
    -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked ~ .menu-item:nth-child(3) {
    transition-duration: 180ms;
    -webkit-transition-duration: 180ms;
    -webkit-transform: translate3d(-60.91639px, -101.99997px, 0);
    transform: translate3d(-60.91639px, -101.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(4) {
    transition-duration: 280ms;
    -webkit-transition-duration: 280ms;
    -webkit-transform: translate3d(-118.0534px, 1.52414px, 0);
    transform: translate3d(-118.0534px, 1.52414px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(5) {
    transition-duration: 380ms;
    -webkit-transition-duration: 380ms;
    -webkit-transform: translate3d(-60.0534px, 100.47586px, 0);
    transform: translate3d(-60.0534px, 100.47586px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(6) {
    transition-duration: 480ms;
    -webkit-transition-duration: 480ms;
    -webkit-transform: translate3d(59.08361px, 99.99997px, 0);
    transform: translate3d(59.08361px, 99.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(7) {
    transition-duration: 580ms;
    -webkit-transition-duration: 580ms;
    -webkit-transform: translate3d(113.96994px, 2.66905px, 0);
    transform: translate3d(113.96994px, 2.66905px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(8) {
    transition-duration: 680ms;
    -webkit-transition-duration: 680ms;
    -webkit-transform: translate3d(57.96994px, -102.33095px, 0);
    transform: translate3d(57.96994px, -102.33095px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(9) {
    transition-duration: 780ms;
    -webkit-transition-duration: 780ms;
    -webkit-transform: translate3d(71.74916px, -74.9997px, 0);
    transform: translate3d(71.74916px, -74.9997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(10) {
    transition-duration: 880ms;
    -webkit-transition-duration: 880ms;
    -webkit-transform: translate3d(-4.25084px, -103.9997px, 0);
    transform: translate3d(-4.25084px, -103.9997px, 0);
}

.menu-open:checked ~ .menu-item {
    z-index: 2;
}

@media (min-width: 992px) {
    .menu {
        min-height: 524px;
    }

    .menu-item,
    .menu-open-button {
        font-size: 14px;
        line-height: 19px;
        height: 8.5rem;
        width: 8.5rem;
    }

    .menu-item {
        font-size: 13px;
        height: 9rem;
        width: 9rem;
    }

    .menu-open-button {
        font-size: 16px;
        height: 17rem;
        width: 17rem;
    }

    .menu-open-button:before {
        height: 32rem;
        width: 32rem;
        border: 10px solid var(--color-primary);
    }

    .menu-open:checked ~ .menu-item:nth-child(3) {
        transition-duration: 180ms;
        -webkit-transition-duration: 180ms;
        -webkit-transform: translate3d(-88.91639px, -155.99997px, 0);
        transform: translate3d(-88.91639px, -155.99997px, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(4) {
        transition-duration: 280ms;
        -webkit-transition-duration: 280ms;
        -webkit-transform: translate3d(-177.0534px, -2.47586px, 0);
        transform: translate3d(-177.0534px, -2.47586px, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(5) {
        transition-duration: 380ms;
        -webkit-transition-duration: 380ms;
        -webkit-transform: translate3d(-97.0534px, 148.47586px, 0);
        transform: translate3d(-97.0534px, 148.47586px, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(6) {
        transition-duration: 480ms;
        -webkit-transition-duration: 480ms;
        -webkit-transform: translate3d(92.08361px, 154.99997px, 0);
        transform: translate3d(92.08361px, 154.99997px, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(7) {
        transition-duration: 580ms;
        -webkit-transition-duration: 580ms;
        -webkit-transform: translate3d(182.96994px, 7.66905px, 0);
        transform: translate3d(182.96994px, 7.66905px, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(8) {
        transition-duration: 680ms;
        -webkit-transition-duration: 680ms;
        -webkit-transform: translate3d(98.96994px, -149.33095px, 0);
        transform: translate3d(98.96994px, -149.33095px, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(9) {
        transition-duration: 780ms;
        -webkit-transition-duration: 780ms;
        -webkit-transform: translate3d(123.74916px, -113.9997px, 0);
        transform: translate3d(123.74916px, -113.9997px, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(10) {
        transition-duration: 880ms;
        -webkit-transition-duration: 880ms;
        -webkit-transform: translate3d(7.74916px, -166.9997px, 0);
        transform: translate3d(7.74916px, -166.9997px, 0);
    }

}

.blue {
    background-color: #669AE1;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.blue:hover {
    color: #669AE1;
    text-shadow: none;
}

.green {
    background-color: #70CC72;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.green:hover {
    color: #70CC72;
    text-shadow: none;
}

.red {
    background-color: #FE4365;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.red:hover {
    color: #FE4365;
    text-shadow: none;
}

.purple {
    background-color: #C49CDE;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.purple:hover {
    color: #C49CDE;
    text-shadow: none;
}

.orange {
    background-color: #FC913A;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.orange:hover {
    color: #FC913A;
    text-shadow: none;
}

.lightblue {
    background-color: #62C2E4;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.lightblue:hover {
    color: #62C2E4;
    text-shadow: none;
}

.credit {
    margin: 24px 20px 120px 0;
    text-align: right;
    color: #EEEEEE;
}

.credit a {
    padding: 8px 0;
    color: #C49CDE;
    text-decoration: none;
    transition: all 0.3s ease 0s;
}

.credit a:hover {
    text-decoration: underline;
}

.colour-white .modal-header h5 {
    color: white !important;
}

.modal-header h5 {
    color: #FFFFFF !important;
}

.h-lg-auto {
    height: 7.8125rem;
    @media (min-width: 992px) {
        height: auto;
    }
}
</style>
