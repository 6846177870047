<template>
    <section class="py-7">
        <img src="../../assets/img/layers/layer-03.png" alt="#" class="layout-bg">
        <img src="../../assets/img/layers/layer-06.png" alt="#" class="layout-bg left">
        <div class="container text-center z-index-4">
            <h2 class="font-weight-semibold mb-5 text-center">
                Our Present Work Location
            </h2>
            <div class="row">
                <div class="col-12">
                    <div class="w-100 fl-x fl-x-cc">
                        <img src="../../assets/img/maps/map-05.png" alt="" class="img-fluid">
                    </div>
                </div>
                <!--                <div class="col-12 col-lg-6 fl-y fl-a-s mb-4 mb-lg-0">-->
                <!--                    <img src="../../assets/img/maps/map-03.png" alt="" class="img-fluid p-5">-->
                <!--                </div>-->
                <!--                <div class="col-12 col-lg-6 fl-x fl-a-c">-->
                <!--                    <iframe class="w-100" src="https://www.youtube.com/embed/YpWB7yXPwBA"-->
                <!--                            title="YouTube video player" frameborder="0"-->
                <!--                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
                <!--                            allowfullscreen></iframe>-->
                <!--                </div>-->
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OurWorks'
};
</script>

<style lang="scss" scoped>
section {
    position: relative;
    background-image: url('../../assets/img/bg/bg-05.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1200px) {
        min-height: 100vh;
    }

}

.layout-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    z-index: 0;

    &.left {
        left: 0%;
        top: 25%;
        height: 50%;
        @media (max-width: 992px) {
            left: -30%;
        }
    }

    //@media (max-width: 992px) {
    //    right: -50%;
    //}
}

iframe {
    height: 16rem;
}

@media (min-width: 992px) {
    iframe {
        height: 20rem;
    }
}

</style>
