<template>
    <main class="bg-impact">
        <div class="container py-7">
            <div class="row py-4">
                <div class="col-12 fl-y fl-a-c mb-4 mb-lg-0">
                    <h2 class="font-weight-semibold mb-5 text-center text-white">
                        Impact Stories
                    </h2>
                </div>
                <!--                <div class="col-12 fl-x fl-j-c mb-5">-->
                <!--                    <div class="mx-4 mx-lg-5 fs-1 tabBtn">-->
                <!--                        Skill Training-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="col-12 fl-y fl-a-c fl-j-c card-height">
                    <ImpactItem/>
                </div>
                <div class="col-12 fl-x fl-j-c mb-5">
                    <div class="mx-4 mx-lg-5 fs-1 tabBtn">
                        Sustainable Development is our focus
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import ImpactItem from '@/views/components/ImpactItem';

export default {
    name: 'ImpactComponent',
    components: { ImpactItem }
};
</script>

<style scoped lang="scss">
.tabBtn {
    color: white;
    font-weight: 400;
    position: relative;
    cursor: pointer;

    &.active {
        font-weight: 600;

        &:before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: -5px;
            right: -5px;
            height: 3px;
            background-color: var(--color-primary);
        }
    }

}
</style>
