<template>
    <div class="banner-size swiper-full-h pos-r">
        <swiper class="banner-size" :options="swiperOption">
            <swiper-slide class="bg-cover w-100 h-100" v-for="(s,i) in slides" :key="i"
                          :class="setClass(i)">
                <div class="overlay-dark"></div>

                <div class="container h-100">
                    <!--                    <div class="row text-white">-->
                    <!--                        <div class="bottom-section col-lg-8 col-xl-6 text-center text-lg-left">-->
                    <div class="fl-y fl-j-c h-100 z-index-4">
                        <h3 class="font-weight-semibold text-white mb-5">Invest in the lives of Marginalised
                            Communities
                            <br> in
                            South Asia</h3>
                        <table class="w-100 text-white">
                            <tr>
                                <!--                            <td>CHILDREN & YOUTH</td>-->
                                <td>Enlighten their minds with EDUCATION</td>
                            </tr>
                            <tr>
                                <!--                            <td>WOMEN & MEN</td>-->
                                <td>Equip their hands with SKILLS & LIVELIHOOD</td>
                            </tr>
                            <tr>
                                <!--                            <td>COMMUNITY</td>-->
                                <td>Enable them to have access to HEALTH-CARE</td>
                            </tr>
                        </table>
                    </div>
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>

            </swiper-slide>
            <!--            <swiper-slide class="slider-2 bg-cover w-100 h-100">-->
            <!--                <div class="container h-100">-->
            <!--&lt;!&ndash;                    <div class="row text-white">&ndash;&gt;-->

            <!--&lt;!&ndash;                        <div class="col-12 col-lg-6">&ndash;&gt;-->
            <!--                            <div class="fl-y fl-j-c h-100 text-white">-->

            <!--                                <h3 class="font-weight-semibold text-white mb-5">Invest in the lives of Marginalised-->
            <!--                                    Communities-->
            <!--                                    <br> in-->
            <!--                                    South Asia</h3>-->
            <!--                                <table class="w-100">-->
            <!--                                    <tr>-->
            <!--                                        &lt;!&ndash;                            <td>CHILDREN & YOUTH</td>&ndash;&gt;-->
            <!--                                        <td>Enlighten Their Minds With SKILL TRAINING & LIVELIHOOD</td>-->
            <!--                                    </tr>-->
            <!--                                    <tr>-->
            <!--                                        &lt;!&ndash;                            <td>WOMEN & MEN</td>&ndash;&gt;-->
            <!--                                        <td>Equip their hands with EDUCATION</td>-->
            <!--                                    </tr>-->
            <!--                                    <tr>-->
            <!--                                        &lt;!&ndash;                            <td>COMMUNITY</td>&ndash;&gt;-->
            <!--                                        <td>Enable them to have access to HEALTH-CARE</td>-->
            <!--                                    </tr>-->
            <!--                                </table>-->

            <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                        </div>-->
            <!--                    </div>-->

            <!--            </swiper-slide>-->
            <div class="swiper-pagination swiper-pagination-white z-index-4" slot="pagination"></div>
            <!--            <div class="swiper-button-prev swiper-button-white z-index-4" slot="button-prev"></div>-->
            <!--            <div class="swiper-button-next swiper-button-white z-index-4" slot="button-next"></div>-->
        </swiper>

    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'BannerNew',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiperOption: {
                // parallax: true,
                // loop: true,
                speed: 1500,
                // spaceBetween: 30,
                effect: 'fade',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            slides: [
                {
                    class: 'slider-1'
                },
                {
                    class: 'slider-2'
                },
                {
                    class: 'slider-3'
                },
                // {
                //     class: 'slider-4'
                // },
                // {
                //     class: 'slider-5'
                // },
                {
                    class: 'slider-6'
                }
            ]
        };
    },
    methods: {
        setClass (i) {
            const keyItem = 'slider-' + String(i + 1);
            const classObj = {};
            classObj[keyItem] = true;
            console.log('classObj', classObj);
            return classObj;
        }
    }
};
</script>

<style scoped lang="scss">
.slider-1 {
    background-image: url(../../assets/img/banner/education/banner-01.png);
    background-position: 30% 0%;
    @media (min-width: 992px) {
        background-position: center;
    }
}

.slider-2 {
    background-image: url(../../assets/img/banner/education/banner-02.png);
    background-position: 69% 0%;
    @media (min-width: 992px) {
        background-position: center;
    }
}

.slider-3 {
    background-image: url(../../assets/img/banner/skill-training/banner-01.jpg);
}

//.slider-4 {
//    background-image: url(../../assets/img/banner/skill-training/banner-02.jpeg);
//}
//
//.slider-5 {
//    background-image: url(../../assets/img/banner/education/banner-03.jpg);
//}

.slider-4 {
    background-image: url(../../assets/img/banner/skill-training/banner-03.jpeg);
}

.banner-size {
    min-height: 100vh;
}

.bannner-h {
    min-height: calc(58px - 100vh);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    background-position: right;
    line-height: 2.5rem;
    @media (min-width: 992px) {
        min-height: calc(84px - 100vh);
    }
    //@media (max-width: 1024px) {
    //    background-position: 80% 76px;
    //}
    h2 {
        font-size: 2.5rem;
        line-height: 4rem;
    }

    @media (max-width: 992px) {
        align-items: center;
        padding-bottom: 20px;

        h2 {
            font-size: 2rem;
            line-height: unset;
        }
    }

    @media (min-width: 992px) {
        .bottom-section {
            position: absolute;
            bottom: 40px;
            left: 15px;
        }
        .content {
            margin-top: -84px !important;
        }
    }

    table {
        td {
            padding-right: 15px;
        }
    }

    //.cont-height {
    //    min-height: calc(100vh - 58px);
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //    position: relative;
    //    @media (min-width: 992px) {
    //        min-height: calc(100vh - 84px);
    //    }
    //}

}

.swiper-full-h {
    .swiper-container {
        .swiper-wrapper {
            height: 100vh !important;
        }
    }
}
</style>
